"use client"

import React from "react"
import ProfileCreationsTab from "./creations/page"
import ProfileCollectionTab from "./collection/page"
import { trpc } from "@/web/clients/trpc"
import Loading from "./loading"

interface PageProps {
  params: any
}

export default function ProfilePage({ params }: PageProps): JSX.Element {
  const { data, isLoading } = trpc.profile.getByUsername.useQuery({
    username: params.username,
  })

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : data?.total_creations_count && data?.total_creations_count > 0 ? (
        <ProfileCreationsTab params={params} />
      ) : (
        <ProfileCollectionTab params={params} />
      )}
    </>
  )
}
