"use client"

import { trpc } from "@/web/clients/trpc"
import React from "react"
import Filters from "../sorting"
import Artworks from "../artworks"
import Loading from "../loading"
import { User } from "@/graphql/generated/apollo/graphql"
import { motion, AnimatePresence } from "framer-motion"

const EmptyCreations = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`
        p-16 text-center text-sr-text-tertiary
        lg:p-24
      `}
    >
      Nothing to see in this collection just yet,{" "}
      <a className="underline" href="https://superrare.com/explore">
        view some art
      </a>
    </motion.div>
  )
}

export default function ProfileCreationsTab({ params }: any): JSX.Element {
  const { data, isLoading } = trpc.profile.getByUsername.useQuery({
    username: params.username,
  })
  return (
    <div className="flex flex-col gap-y-6">
      <AnimatePresence mode="wait">
        {isLoading ? (
          <Loading />
        ) : data?.total_collections_count == 0 ? (
          <EmptyCreations />
        ) : (
          <>
            <Filters />
            <Artworks profile={data as User} type="collection" />
          </>
        )}
      </AnimatePresence>
    </div>
  )
}
