import { useEffect, useState } from "react"

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (): void => {
      const width = window.innerWidth
      setWindowWidth(width)
      setIsMobile(width <= 768)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return {
    isMobile,
    windowWidth,
  }
}
