"use client"

import { FilterValue, useProfileStore } from "@/web/stores/profile"
import { Checkbox } from "@/ui/checkbox"
import { AnimatePresence, motion } from "framer-motion"
import { Dialog } from "@/ui/dialog"
import { useMobile } from "@/web/hooks/useMobile"
import { useEffect } from "react"
import { useRouter } from "next/navigation"
import { Icon } from "@/ui/icon"
export interface FilterOption {
  label: string
  value: string
  count?: number
  disabled?: boolean
  subOptions?: FilterOption[]
}

export interface FilterGroup {
  label: string
  key: string
  options: FilterOption[]
}

interface FilterOptionsProps {
  filters: FilterGroup[]
}

function FilterOptions({ filters }: FilterOptionsProps) {
  const { filters: storeFilters, setFilters } = useProfileStore()
  const router = useRouter()

  function handleFilterChange(
    key: string,
    value: string,
    parentValue?: string
  ) {
    const updatedFilters: Record<string, FilterValue> = { ...storeFilters }

    if (parentValue) {
      if (!updatedFilters[key]) updatedFilters[key] = {}
      if (!updatedFilters[key][parentValue])
        updatedFilters[key][parentValue] = []

      const childIndex = (updatedFilters[key][parentValue] as string[]).indexOf(
        value
      )
      if (childIndex > -1) {
        ;(updatedFilters[key][parentValue] as string[]).splice(childIndex, 1)
      } else {
        ;(updatedFilters[key][parentValue] as string[]).push(value)
      }

      // Check if all children are selected for the parent
      const allSubOptions =
        filters
          .find((f) => f.key === key)
          ?.options.find((o) => o.value === parentValue)
          ?.subOptions?.map((so) => so.value) || []
      const allChildrenSelected = allSubOptions.every((subOption) =>
        (updatedFilters[key][parentValue] as string[]).includes(subOption)
      )
      if (allChildrenSelected) {
        // If all children are selected
        updatedFilters[key] = {
          ...updatedFilters[key],
          [parentValue]: allSubOptions,
        }
      } else if (updatedFilters[key][parentValue].length === 0) {
        // If no children are selected
        delete updatedFilters[key][parentValue]
      }
    } else {
      // Handling parent options
      if (updatedFilters[key] && updatedFilters[key][value]) {
        // If parent is already selected
        delete updatedFilters[key][value]
      } else {
        // Select the parent and all its children
        const allSubOptions =
          filters
            .find((f) => f.key === key)
            ?.options.find((o) => o.value === value)
            ?.subOptions?.map((so) => so.value) || []
        updatedFilters[key] = { ...updatedFilters[key], [value]: allSubOptions }
      }
    }

    setFilters(updatedFilters)
  }

  useEffect(() => {
    const queryStringParts: string[] = []
    Object.entries(storeFilters).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        // Handle objects (both top-level and suboptions)
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (Array.isArray(subValue)) {
            // Always append the key to the queryStringParts, even if the array is empty
            const uniqueKey = `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]`
            if (subValue.length > 0) {
              // For non-empty arrays, append the key and its values as a comma-separated string
              queryStringParts.push(
                `${uniqueKey}=${subValue.map(encodeURIComponent).join(",")}`
              )
            } else {
              // For empty arrays, append the key and subkey
              queryStringParts.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(subKey)}`
              )
            }
          }
        })
      } else if (Array.isArray(value)) {
        // Handle top-level arrays
        if (Array.isArray(value) && value) {
          queryStringParts.push(
            `${encodeURIComponent(key)}=${(value as any[]).map(encodeURIComponent).join(",")}`
          )
        } else {
          queryStringParts.push(`${encodeURIComponent(key)}=[]`)
        }
      }
    })

    const queryString = queryStringParts.join("&")
    const updatedUrl = `${window.location.pathname}?${queryString}`
    router.replace(updatedUrl)
  }, [storeFilters])

  const isOptionSelected = (
    filterKey: string,
    optionValue: string
  ): boolean => {
    const filterValue = storeFilters[filterKey]
    if (Array.isArray(filterValue)) {
      return filterValue.includes(optionValue)
    } else if (typeof filterValue === "object" && filterValue !== null) {
      return Object.keys(filterValue).includes(optionValue)
    }
    return false
  }

  return (
    <>
      {filters.map((filter) => (
        <div
          key={filter.key}
          className="sticky top-36 border-t border-sr-border-primary py-2"
        >
          <h3 className="mb-6 text-sm uppercase text-sr-text-primary">
            {filter.label}
          </h3>
          <div className="mt-2 flex flex-col gap-2 text-base">
            {filter.options.map((option) => (
              <div key={`${filter.key}-${option.value}`}>
                <Checkbox
                  id={`${filter.key}-${option.value}`}
                  checked={isOptionSelected(filter.key, option.value)}
                  disabled={option.disabled || option.count === 0}
                  onCheckedChange={() => {
                    handleFilterChange(filter.key, option.value)
                  }}
                >
                  {option.label}{" "}
                  <span className="text-sr-text-tertiary">
                    ({option.count})
                  </span>
                </Checkbox>
                {option.subOptions && (
                  <div className="flex flex-col gap-y-2 pl-6 pt-2 text-base">
                    {option.subOptions.map((subOption) => (
                      <Checkbox
                        key={`${filter.key}-${subOption.value}`}
                        id={`${filter.key}-${subOption.value}`}
                        disabled={subOption.disabled || subOption.count === 0}
                        checked={
                          typeof storeFilters[filter.key] === "object" &&
                          storeFilters[filter.key] !== null &&
                          typeof storeFilters[filter.key][option.value] !==
                            "undefined" &&
                          (
                            storeFilters[filter.key][
                              option.value
                            ] as unknown as string[]
                          )?.includes(subOption.value)
                        }
                        onCheckedChange={() => {
                          handleFilterChange(
                            filter.key,
                            subOption.value,
                            option.value
                          )
                        }}
                      >
                        {subOption.label}
                        <span className="ml-1 text-sr-text-tertiary">
                          ({subOption.count})
                        </span>
                      </Checkbox>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

interface FiltersProps {
  availableFilters: FilterGroup[]
}

export default function Filters({
  availableFilters,
}: FiltersProps): JSX.Element {
  const {
    isFiltersOpen,
    setFilters,
    clearFilters,
    countActiveFilters,
    toggleFilters,
  } = useProfileStore()
  const { isMobile } = useMobile()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const updatedFilters: Record<string, FilterValue> = {}

    urlParams.forEach((value, key) => {
      if (key.includes("[")) {
        // Suboption filter
        const [filterKey, subOptionKey] = key.split("[")
        const cleanedSubOptionKey = subOptionKey.replace("]", "")

        if (!updatedFilters[filterKey]) {
          updatedFilters[filterKey] = {}
        }

        updatedFilters[filterKey][cleanedSubOptionKey] = value.split(",")
      } else {
        // Top-level filter
        if (!updatedFilters[key]) {
          updatedFilters[key] = {}
        }
        updatedFilters[key][value] = []
      }
    })

    setFilters(updatedFilters)
  }, [])

  return (
    <>
      {isMobile && isFiltersOpen && (
        <Dialog open={isFiltersOpen} onOpenChange={toggleFilters}>
          <Dialog.Content title="Filters">
            <div
              className={`
                mb-4
                lg:hidden
              `}
            >
              <AnimatePresence mode="wait">
                {countActiveFilters() > 0 ? (
                  <motion.button
                    key="clear-filters"
                    animate={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: -15 }}
                    exit={{ opacity: 0, y: -15 }}
                    className={`
                      text-sm text-sr-text-secondary
                      hover:underline
                    `}
                    onClick={clearFilters}
                  >
                    Clear ({countActiveFilters()})
                  </motion.button>
                ) : (
                  <motion.span
                    key="showing-all"
                    animate={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: 15 }}
                    exit={{ opacity: 0, y: 15 }}
                    className="cursor-pointer text-sm text-sr-text-secondary"
                    onClick={toggleFilters}
                  >
                    Showing All
                  </motion.span>
                )}
              </AnimatePresence>
            </div>
            <FilterOptions filters={availableFilters} />
          </Dialog.Content>
        </Dialog>
      )}

      {!isMobile && isFiltersOpen && (
        <motion.aside
          key={isFiltersOpen ? "open" : "closed"}
          initial={{ x: -100, width: 0 }}
          animate={{ x: 0, width: "25%" }}
          exit={{ x: -110, width: 0, opacity: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 24 }}
        >
          <FilterOptions filters={availableFilters} />
        </motion.aside>
      )}
    </>
  )
}

export const FiltersToggler = (): JSX.Element => {
  const { toggleFilters, countActiveFilters, clearFilters } = useProfileStore()

  return (
    <>
      <button
        className={`
          flex size-8 items-center justify-center rounded-md border border-sr-border-secondary
          hover:border-sr-border-primary
        `}
        onClick={toggleFilters}
      >
        <Icon name="filters" invertOnDark className="h-4 w-4" />
      </button>
      <AnimatePresence mode="wait">
        {countActiveFilters() > 0 ? (
          <motion.button
            key="clear-filters"
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -15 }}
            exit={{ opacity: 0, y: -15 }}
            className={`
              ml-3 text-sm text-sr-text-secondary
              hover:underline
            `}
            onClick={clearFilters}
          >
            Clear ({countActiveFilters()})
          </motion.button>
        ) : (
          <motion.span
            key="showing-all"
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 15 }}
            exit={{ opacity: 0, y: 15 }}
            className="ml-3 cursor-pointer text-sm text-sr-text-secondary"
            onClick={toggleFilters}
          >
            Showing All
          </motion.span>
        )}
      </AnimatePresence>
    </>
  )
}
