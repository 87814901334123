import networksMap from "@/constants/networksMap"

export const getSrTokenContracts = (): string[] => {
  if (process.env.NEXT_PUBLIC_CHAIN_ID === networksMap.sepolia.toString())
    return ["0x33bf9625e613b0abfc48738552422d6ffd48059c"]

  /* mainnet */
  return [
    "0x41a322b28d0ff354040e2cbc676f0320d8c8850d",
    "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0",
  ]
}
