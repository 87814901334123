import { AsyncBlueprintToken } from "@/graphql/types/AsyncBlueprintToken"
import { Erc721Creator } from "@/graphql/types/Erc721Creator"
import { Erc721Metadata } from "@/graphql/types/Erc721Metadata"
import { Erc721MetadataMedia } from "@/graphql/types/Erc721MetadataMedia"
import { Erc721Owner } from "@/graphql/types/Erc721Owner"
import { Erc721Tag } from "@/graphql/types/Erc721Tag"
import { NftAttribute } from "@/graphql/types/NftAttribute"
import { NftContract } from "@/graphql/types/NftContract"
import { NftImage } from "@/graphql/types/NftImage"
import { SpaceNftContract } from "@/graphql/types/SpaceNftContract"
import { gql } from "@apollo/client"

export const CREATIONS_QUERY = gql`
  query Creations($creationsInput: ProfileCreationsInput!) {
    creations(creationsInput: $creationsInput) {
      token_id
      date_minted
      contract_address
      universal_token_id
      nft_contract {
        token_contract_address
        name
        symbol
        erc721_tokens_total_count
        collectors {
          username
          avatar
          ethaddress
          ethereum_address
        }
      }
      current_offer {
        amount
        bidder {
          username
          ethaddress
          ethereum_address
          avatar
        }
        currency {
          address
          type
        }
      }
      erc721_creator {
        address
        contract_address
        creator {
          username
          bio
          minimum_bid
          avatar
          ethaddress
          ethereum_address
        }
      }
      erc721_metadata {
        collection_name
        collection_symbol
        metadata
      }
      nft_image {
        image_full
        image_hero
        image_w800
        image_w390
        image_small
        image_video
        image_medium
        image_medium_imgix_cdn
        image_blurred
        image_blurred_w390_imgix_cdn
        image_video_w800
        image_video_w390
        image_video_small
        image_video_medium
        image_video_medium_imgix_cdn
        image_blurred_w800
        image_blurred_w390
        image_artwork_detail
      }
      erc721_metadata_media {
        mime_type
        uri
      }
      erc721_owner {
        contract_address
        owner_address
        owner {
          username
          avatar
          ethaddress
          ethereum_address
        }
      }
      current_offer {
        amount
      }
      current_sale_price {
        token_id
        amount
        currency_address {
          address
          type
        }
      }
      most_recent_sale {
        nft_event_type
        event_timestamp
        usd_value
        auction_settled_event {
          amount
          bidder {
            id
            username
            avatar
            ethaddress
            ethereum_address
          }
          currency {
            address
            type
          }
        }
        erc721_accepted_bid {
          amount
          bidder {
            id
            username
            avatar
            ethaddress
            ethereum_address
          }
          currency {
            address
            type
          }
        }
        erc721_market_sold {
          amount
          currency {
            address
            type
          }
          buyer {
            id
            username
            avatar
            ethaddress
            ethereum_address
          }
        }
      }
      nft_events {
        id
        nft_event_type
      }
      nft_auctions(
        auction_state: [PENDING_AUCTION, RUNNING_AUCTION, UNSETTLED_AUCTION]
      ) {
        current_nft_auction_bid {
          amount
          currency {
            address
            type
          }
          bidder_address
          bidder {
            username
            avatar
            ethaddress
            ethereum_address
          }
        }
        currency_address
        reserve_price
        starting_time
        ending_time
        auction_type
        auction_state
        minimum_bid
        currency_address
      }
      nft_events {
        nft_event_type
        auction_ended_event {
          amount
        }
        erc721_accepted_bid {
          amount
        }
        erc721_bid {
          amount
        }
      }
      space_nft_contract {
        operator_address
        token_contract_address
        name
        symbol
      }
    }
  }
`

export enum SeriesFilter {
  ALL = "ALL",
  NONE = "NONE",
  ONLY_ONE_OF_ONES = "ONLY_ONE_OF_ONES",
  ONLY_SERIES = "ONLY_SERIES",
}

export type ProfileCreationsInput = {
  orderBy: string
  skip: number
  take: number
  seriesFilter?: SeriesFilter
  tokenContractAddresses?: string[]
  creatorAddresses: string[]
  universalTokenIdFilter?: string[]
}

export type CreationsResult = {
  token_id: number
  contract_address: string
  universal_token_id: string
  erc721_metadata: Erc721Metadata
  erc721_metadata_media: Erc721MetadataMedia
  erc721_creator: Erc721Creator
  erc721_owner: Erc721Owner
  nft_image: NftImage
  nft_contract: NftContract
  activity_timestamp: Date
  is_genesis: boolean
  date_minted: Date
  erc721_tags: Erc721Tag[]
  nft_attributes: NftAttribute[]
  async_blueprint_token: AsyncBlueprintToken
  space_nft_contract: SpaceNftContract
  image?: string
  video?: string
}
