"use client"

import { ProfileOrderBy, User } from "@/graphql/generated/apollo/graphql"
import { useProfileStore } from "@/web/stores/profile"
import { trpc } from "@/web/clients/trpc"
import GridCard from "@/components/cards/GridCard"
import InfiniteScroll from "react-infinite-scroll-component"
import { Loader } from "@/ui/loader"
import Loading from "./loading"
import { AnimatePresence, motion } from "framer-motion"
import { cn } from "@/utils/cn"
import { SeriesFilter } from "@/graphql/queries/creations"
import { getSrTokenContracts } from "@/utils/srTokenContracts"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Erc721TokenExtended } from "@/components/cards/base"

export default function Artworks({
  profile,
  type,
}: {
  profile: User
  type: "creations" | "collection" | "favorites"
}): JSX.Element {
  const { orderBy, filters, isFiltersOpen, viewMode } = useProfileStore()

  // seriesFilter must be either ONLY_SERIES, ONLY_ONE_OF_ONES, or undefined
  let seriesFilter: SeriesFilter | undefined
  let contractAddresses: string[] = []
  if (
    typeof filters.artwork === "object" &&
    filters.artwork !== null &&
    "ONLY_ONE_OF_ONES" in filters.artwork &&
    "ONLY_SERIES" in filters.artwork
  ) {
    seriesFilter = undefined
    contractAddresses = getSrTokenContracts()
  } else if (
    typeof filters.artwork === "object" &&
    filters.artwork !== null &&
    "ONLY_ONE_OF_ONES" in filters.artwork
  ) {
    seriesFilter = SeriesFilter.ONLY_ONE_OF_ONES
  } else if (
    typeof filters.artwork === "object" &&
    filters.artwork !== null &&
    "ONLY_SERIES" in filters.artwork
  ) {
    seriesFilter = SeriesFilter.ONLY_SERIES
  }

  if (
    typeof filters.artwork === "object" &&
    Array.isArray(filters.artwork.ONLY_SERIES)
  ) {
    contractAddresses = [...contractAddresses, ...filters.artwork.ONLY_SERIES]
  }

  let query
  switch (type) {
    case "creations":
      query = trpc.profile.getCreations.useInfiniteQuery(
        {
          orderBy: orderBy as ProfileOrderBy,
          skip: 0,
          take: 12,
          creatorAddresses: [profile.ethereum_address || ""],
          contractAddresses: contractAddresses,
          seriesFilter: seriesFilter,
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
          initialCursor: 1,
        }
      )
      break
    case "collection":
      query = trpc.profile.getCollection.useInfiniteQuery(
        {
          orderBy: orderBy as ProfileOrderBy,
          skip: 0,
          take: 12,
          ownerAddresses: [profile.ethereum_address || ""],
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
          initialCursor: 1,
        }
      )
      break
    case "favorites":
      query = trpc.profile.getFavorites.useInfiniteQuery(
        {
          skip: 0,
          take: 12,
          ethAddress: profile.ethereum_address,
        },
        {
          getNextPageParam: (lastPage) => lastPage.nextCursor,
          initialCursor: 1,
        }
      )
      break
  }

  const { data, isLoading, hasNextPage, fetchNextPage } = query

  return (
    <>
      {isLoading ? (
        <motion.div className="flex w-full items-center justify-center">
          <Loading />
        </motion.div>
      ) : (
        <motion.div className="w-full">
          <InfiniteScroll
            dataLength={
              data?.pages.flatMap((page) => page.artworks).length ?? 0
            }
            next={async () => {
              await fetchNextPage()
            }}
            hasMore={
              !!hasNextPage &&
              (data?.pages?.[data.pages.length - 1]?.artworks?.length ?? 0) > 0
            }
            loader={
              <div className="flex justify-center pt-4">
                <Loader kind="simple" className="w-24" />
              </div>
            }
            scrollableTarget="document"
            scrollThreshold={0.6}
          >
            {viewMode === "masonry" && (
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry gutter="32px">
                    {data?.pages
                      .flatMap((page) => page.artworks)
                      .map((artwork: Erc721TokenExtended, index) => (
                        <div className="overflow-hidden rounded-md" key={index}>
                          <img
                            src={artwork.nft_image?.image_w800 || ""}
                            key={index}
                            alt=""
                          />
                        </div>
                      ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            )}
            {viewMode === "grid" && (
              <div
                className={cn("grid grid-cols-1", {
                  "gap-16 sm:grid-cols-2 xl:grid-cols-4": !isFiltersOpen,
                  "gap-12 sm:grid-cols-1 xl:grid-cols-3": isFiltersOpen,
                })}
              >
                <AnimatePresence>
                  {data?.pages
                    .flatMap((page) => page.artworks)
                    .map((artwork, index) => (
                      <GridCard
                        kind="basic"
                        key={index + artwork.contract_address}
                        artwork={artwork}
                        // showPinning={
                        //   (type === "collection" || type === "creations") &&
                        //   isOwnProfile(profile.ethereum_address)
                        // }
                        showPinning={true}
                        favorites={type === "favorites"}
                        page="Profile"
                      />
                    ))}
                </AnimatePresence>
              </div>
            )}
          </InfiniteScroll>
        </motion.div>
      )}
    </>
  )
}
