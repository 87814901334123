import { ProfileOrderBy } from "@/graphql/generated/apollo/graphql"

export const ReleasesOrderByFriendlyNames = {
  [ProfileOrderBy.DateMintedDesc]: "Date Minted (Newest First)",
  [ProfileOrderBy.DateMintedAsc]: "Date Minted (Oldest First)",
  [ProfileOrderBy.PriceDesc]: "Price (High to Low)",
  [ProfileOrderBy.PriceAsc]: "Price (Low to High)",
  [ProfileOrderBy.RecentlySold]: "Recently Sold",
}

export const ProfileOrderByFriendlyNames = {
  [ProfileOrderBy.DateMintedDesc]: "Newest",
  [ProfileOrderBy.DateMintedAsc]: "Oldest",
  [ProfileOrderBy.PriceDesc]: "Highest Price",
  [ProfileOrderBy.PriceAsc]: "Lowest Price",
  [ProfileOrderBy.RecentlySold]: "Recently Sold",
}
