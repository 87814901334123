"use client"

import { trpc } from "@/web/clients/trpc"
import React from "react"
import Sorting from "../sorting"
import Filters, { FilterGroup, FiltersToggler } from "../filters"
import Artworks from "../artworks"
import Loading from "../loading"
import { User } from "@/graphql/generated/apollo/graphql"
import { motion } from "framer-motion"
import { redirect } from "next/navigation"
import { SeriesFilter } from "@/graphql/queries/creations"
//import ViewMode from "../view-mode" (hidden for now)

const EmptyCreations = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`
        p-16 text-center text-sr-text-tertiary
        lg:p-24
      `}
    >
      Nothing to see here just yet, follow this artist to see their creations in
      your feed
    </motion.div>
  )
}

export default function ProfileCreationsTab({ params }: any): JSX.Element {
  const { data, isLoading } = trpc.profile.getByUsername.useQuery({
    username: params.username,
  })

  if (data?.total_creations_count === 0) {
    redirect(`/${params.username}/collection`)
  }

  const availableFilters: FilterGroup[] = [
    {
      label: "Artwork",
      key: "artwork",
      options: [
        {
          label: "1 of 1's",
          value: SeriesFilter.ONLY_ONE_OF_ONES,
          count: data?.total_creations_count_one_of_ones,
        },
        {
          label: "Series",
          value: SeriesFilter.ONLY_SERIES,
          count: data?.total_creations_count_series,
          subOptions: data?.nft_contracts
            ?.map(
              (
                contract
              ): { label: string; value: string; count: number } | undefined =>
                contract &&
                "name" in contract &&
                "token_contract_address" in contract &&
                "erc721_tokens_total_count" in contract
                  ? {
                      label: contract.name,
                      value: contract.token_contract_address,
                      count: contract.erc721_tokens_total_count,
                    }
                  : undefined
            )
            ?.filter(
              (item): item is { label: string; value: string; count: number } =>
                !!item
            ),
        },
      ],
    },
  ]

  return (
    <div className="flex flex-col gap-y-6">
      {isLoading ? (
        <Loading />
      ) : data?.total_creations_count === 0 ? (
        <EmptyCreations />
      ) : (
        <>
          <nav className="sticky top-[64px] z-10 mt-2 flex items-center justify-between bg-sr-bg-primary py-3">
            <FiltersToggler />
            <Sorting />
          </nav>
          <div className="flex w-full gap-x-12">
            <Filters availableFilters={availableFilters} />
            <Artworks profile={data as User} type="creations" />
          </div>
        </>
      )}
    </div>
  )
}
