"use client"

import { ProfileOrderBy } from "@/graphql/generated/apollo/graphql"
import { ProfileOrderByFriendlyNames } from "@/graphql/types/ProfileOrderBy"
import { useProfileStore } from "@/web/stores/profile"
import { Icon } from "@/ui/icon"
import { heapTrack } from "@/utils/heap"
import * as Popover from "@radix-ui/react-popover"

export default function Filters(): JSX.Element {
  const { orderBy, setOrderBy } = useProfileStore()

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          title="sort"
          className={`
            ml-auto flex size-8 items-center justify-center rounded-md border border-sr-border-secondary
            hover:border-sr-border-primary
          `}
        >
          <Icon name="sort" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={6}
          align="end"
          className="z-40 rounded-md border border-sr-border-primary bg-sr-bg-primary p-3"
        >
          <ul>
            {Object.entries(ProfileOrderByFriendlyNames).map(([key, value]) => (
              <li key={key}>
                <button
                  onClick={() => {
                    setOrderBy(key as ProfileOrderBy)
                    heapTrack("profile filter selected", {
                      filter: key,
                    })
                  }}
                  className={`
                    flex w-full items-center rounded-md p-2 text-left
                    hover:bg-day-grey-3 hover:dark:bg-night-grey-4
                  `}
                  title={value}
                  aria-label={value}
                >
                  <span className="w-6">
                    {orderBy === key && <Icon name="check" invertOnDark />}
                  </span>
                  {value}
                </button>
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
