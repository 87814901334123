export const MAINNET_ID = 1
export const TESTNET_ID = 5
export const SEPOLIA_ID = 5

const networksMap = Object.freeze({
  sepolia: SEPOLIA_ID,
  testnet: TESTNET_ID,
  mainnet: MAINNET_ID,
})

export default networksMap
